import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Digital Transformation Agency`,
    content:
      "We are a trusted digital transformation agency facilitating the timely and convenient adoption of your digital products to accelerate business growth.",
  };
  const whatServiceContent = {
    title: "What do Digital <span class='h1-span'>Transformation Agencies do?</span>",
    content: [
      "It is quintessential in the competitive digital world to transform your digital offerings.",
      "Hence, a need for a digital transformation consultant arises to transform existing products in terms of technology, processes, and experience.",
      "They offer strategic guidance, technological assessment and implementation, process optimization, change management, and other digital transformation services to assist businesses in adapting to changing market dynamics.",
      "Similarly, as a digital transformation agency, we leverage our expertise to drive innovation and improve customer experience.",
    ],
    img: ["what-service-image-digitaltransform.webp"],
    imageTitle: 'Digital Transformation',
    imageAlt: '',
  };

  const commonContent = {
    title: "Who needs Digital Transformation Agency?",
    para: "Businesses across industries require digital transformation agencies as they navigate, simplify, and offer digital transformation strategies to streamline the product complexities of all business types.",
    img: ["who-needs-digitaltransform.webp"],
    imageTitle: 'Digital Transformation Services',
    imageAlt: '',
    content: [
      {
        title: "Traditional Retailers",
        text: "Companies who want to adapt to changing customer behavior and leverage technology to enhance their digital experience.",
      },
      {
        title: "Financial Institutions",
        text: "Companies who want to leverage technology to streamline operations, enhance customer efficiency, and optimize their digital offerings.",
      },
      {
        title: "Healthcare Organizations",
        text: "Companies who want to increase the adoption rate through innovative technology and streamline administrative processes for increased efficiency.",
      },
      {
        title: "Manufacturing Companies",
        text: "Companies who want to leverage technology for operational efficiency and optimize supply chain management to increase productivity and industrial competitiveness.",
      },
    ],
  };

  const commonContent2 = {
    title:
      "How Digital Transformation Services Benefit Businesses?",
    para: "Digital Transformation Services offers innovative ideas and transforms your business's traditional processes to stay competitive in the digital landscape.",
    img: ["what-are-benefits-digitaltransform.webp"],
    imageTitle: 'Benefits of Digital Transformation',
    imageAlt: '',
    content: [
      {
        title: "Increased Productivity",
        text: "Digital transformation services automate manual processes and streamline workflow, increasing efficiency and long-term growth.",
      },
      {
        title: "Data-driven Insights",
        text: "Digital transformation uses advanced tools and techniques to collect, analyze, and interpret data to offer actionable insights.",
      },
      {
        title: "Enhanced Customer Experience",
        text: "Digital transformation consulting enables businesses to deliver custom solutions according to user preferences across multiple touchpoints.",
      },
      {
        title: "Competitive Advantage",
        text: "Digital transformation consultants help businesses to adapt quickly to the changing market dynamics by tapping into new opportunities.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why choose Octet as Digital Transformation Company?",
    linkText: ``,
    para: `We are a leading <a href="/" target="_blank" rel="noreferrer">UI UX design agency</a> with a strong reputation and industrial knowledge. We deliver innovative and customized digital transformation solutions to transform your business and foster an innovative culture.`,
    img: ["why-opt-for-digitaltransform.webp"],
    imageTitle: 'Digital Transformation Agency',
    imageAlt: '',
    content: [
      {
        title: "User-Centric Expertise",
        text: "We focus on transforming your digital product with a user-centric approach to drive satisfaction and foster customer loyalty.",
      },
      {
        title: "Agile & Collaborative Approach",
        text: "We focus on a collaborative approach for continuous improvement and involve stakeholders to incorporate their feedback during the transformation process.",
      },
      {
        title: "Data-Driven Decision",
        text: "We use analytical tools and technologies to optimize strategies, make informed decisions, and drive measurable results.",
      },
      {
        title: "Multi-Platform Integration",
        text: "We integrate multiple platforms into a unified digital ecosystem to enable smooth data flow and transform businesses.",
      },
    ],
  };
  const commonContent4 = {
    title: "How do we offer Digital <span class='h1-span'>Transformation Services?</span>",
    para: "We offer comprehensive digital transformation services to leverage the full potential of your digital offerings. From conducting market research to testing your digital products, our digital transformation consultant offers strategic insights to deliver tailor-made, tangible results.",
    img: ["how-we-conduct-digitaltransform.webp"],
    imageTitle: 'Digital Transformation Process',
    imageAlt: '',
    content: [
      {
        title: "1. Research",
        text: "We believe in carrying out due diligence to gain insights into what users think of your product and how they use it to solve their problems.",
      },
      {
        title: "4. Design & Test",
        text: "Once all the above things are in place, we start by developing the user flows, wireframes, and information architecture of your product.",
      },
      {
        title: "2. Gap analysis",
        text: "We conduct a gap analysis to find gaps between the intended customer journey and the actual customer journey and set goals for each service area.",
      },
      {
        title: "5. Translating processes",
        text: "When all the processes are lined up, we begin to design products that will enable digital transformation.",
      },
      {
        title: "3. Creating a Digital Transformation Roadmap",
        text: "Once the destination i.e. the end business goal is set, we chart out the roadmap for the business to transition into the digital space.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "What Invest in Digital <span class='h1-span'>Transformation Agency?</span>",
    para: "Investing in a digital transformation company brings innovative technologies, updated processes, and data-driven insights that can revolutionize your business and bring long-term gains.",
    content: [
      {
        title: "86% Customer Experience",
        text: "Digital Transformation Service offers seamless interaction to customers, which improves customer experience.",
      },
      {
        title: "40% Increased Interaction",
        text: "Through digital transformation, you can increase customer interactions, engagement, and loyalty towards your business.",
      },
      {
        title: "30% Customer Satisfaction",
        text: "Businesses that deliver customized and interactive experiences receive 30% more customer satisfaction.",
      },
      {
        title: "74% Willingness to Pay",
        text: "Customers are willing to pay for a business offering a well-optimized and interactive platform.",
      },
    ],
    desc: "*These data are from public sources, we do not claim to owe these stats.",
  };
  const cta = {
    title: "Seeking Expert Digital Transformation Solutions?",
  };
  const cta2 = {
    title: "Discover Our Digital <span class='h1-span'>Transformation Services!</span>",
  };
  const cta3 = {
    title: "Take the leap into digital transformation!",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Kesari",
      desc: "An ERP Platform",
      Industry: "Enterprise",
      firstAlt: "",
      firstTitle: "Bookings Overview",
      secondAlt: "",
      secondTitle: "All Packages Screen",
      WhatWeDid: [
        {
          title: "Digital Transformation",
          link: "/digital-transformation/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Angular Development",
          link: "/angularjs-development/",
        },
      ],
      link: "/project/ui-ux-design-kesari-tap/",
      images: ["kesari-web.webp", "kesari-2.webp"],
    },
    {
      title: "Auto Service Booking",
      desc: "A CRM Platform For Car Servicing",
      Industry: "SaaS",
      firstAlt: "",
      firstTitle: "Calendar",
      secondAlt: "",
      secondTitle: "Performance Dashboard",
      WhatWeDid: [
        {
          title: "Product Development",
          link: "/product-development/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "User Acquisition Consultation",
          link: "/user-acquisition-consulting/",
        },
      ],
      link: "/project/ui-ux-auto-service-booking/",
      images: ["auto-service-web.webp", "auto-service-web-2.webp"],
    },
  ];

  const Questions = [
    "What are the digital transformation services you offer?",
    "When can I implement my digital transformation strategies?",
    "When do digital transformation services start delivering results?",
    "How do you evaluate the result of digital transformation initiatives?",
    "How much do you charge for digital transformation services?",
  ];
  const clientsTitle = "Companies that we <span class='h1-span'>Digitally Transformed</span>";
  const faqDes = `We understand you might have more questions regarding our digital transformation services. As a digital transformation agency, we have compiled some common questions asked by businesses. However, if you still have any queries, please <a href="/contact-us/" title="Contact Us">contact us</a>. `;

  const faqData = [
    {
      para: [
        `We offer a comprehensive digital transformation service tailored to meet your specific needs. Through our services, we look into every aspect of the transformation journey from strategy to implementation..`,
      ],
      list: [
        `<span><h4 class="inline">Transforming Product:</h4></span> - We assess your existing product, identify areas of improvement, and upgrade it for future-ready use.`,
        `<span><h4 class="inline">Transforming Process:</h4></span> -  We understand your existing business processes and devise ways to simplify and streamline them.`,
        `<span><h4 class="inline">Transforming Experience:</h4></span> - We architect seamless customer journeys, omnichannel engagement, and modernization of your existing legacy systems.`,
        `<span><h4 class="inline">Facilitating Adoption:</h4></span> - We familiarize the prospective users & stakeholders for easy and quick adoption of newer systems.`,
      ],
    },
    {
      para: [
        `You can implement digital transformation strategies depending on your business objective and your business's readiness to change. However, digital transformation is continuous, and the solutions depend on the changing market trends.`,
        `We will advise you to implement your strategies as soon as you have a defined plan and the necessary support and resources to quickly position and thrive in the digital landscape.`,
      ],
    },
    {
      para: [
        `Digital transformation services start delivering results at different stages of the transformation journey. While some implementations can offer early results, other solutions may take longer to offer measurable insights. `,
        `Its timeline varies depending on the project's complexity, business goals, etc., and may take a few weeks to years to deliver the desired results. `,
      ],
    },
    {
      para: [
        `To measure the success of your digital transformation initiatives, we define the Key Performance Indicators (KPIs) aligned with your business goals. The KPIs include increased revenue, customer satisfaction scores, conversion metrics, etc.`,
        `These indicators measure the strategies' performance and help modify the solutions for sustainable business growth.`,
      ],
    },
    {
      para: [
        `The cost of digital transformation consulting depends on certain specified factors, such as organization size, the scope of the transformation project, the analytical tools and technologies involved, and the level of expertise required.`,
        `We request you to <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> for a detailed quotation on the digital transformation service.`,
      ],
    },
  ];

  return (
    <>
    <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "What are the digital transformation services you offer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer a comprehensive digital transformation service tailored to meet your specific needs. Through our services, we look into every aspect of the transformation journey from strategy to implementation. 
            Transforming Product
            We assess your existing product, identify areas of improvement, and upgrade it for future-ready use.
            Transforming Process
            We understand your existing business processes and devise ways to simplify and streamline them.
            Transforming Experience
            We architect seamless customer journeys, omnichannel engagement, and modernization of your existing legacy systems.
            Facilitating Adoption
            We familiarize the prospective users & stakeholders for easy and quick adoption of newer systems."
                }
              },{
                "@type": "Question",
                "name": "When can I implement my digital transformation strategies?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can implement digital transformation strategies depending on your business objective and your business's readiness to change. However, digital transformation is continuous, and the solutions depend on the changing market trends. 
            
            We will advise you to implement your strategies as soon as you have a defined plan and the necessary support and resources to quickly position and thrive in the digital landscape."
                }
              },{
                "@type": "Question",
                "name": "When do digital transformation services start delivering results?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Digital transformation services start delivering results at different stages of the transformation journey. While some implementations can offer early results, other solutions may take longer to offer measurable insights. 
            
            Its timeline varies depending on the project's complexity, business goals, etc., and may take a few weeks to years to deliver the desired results."
                }
              },{
                "@type": "Question",
                "name": "How do you evaluate the result of digital transformation initiatives?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "To measure the success of your digital transformation initiatives, we define the Key Performance Indicators (KPIs) aligned with your business goals. The KPIs include increased revenue, customer satisfaction scores, conversion metrics, etc. 
            
            These indicators measure the strategies' performance and help modify the solutions for sustainable business growth."
                }
              },{
                "@type": "Question",
                "name": "How much do you charge for digital transformation services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of digital transformation consulting depends on certain specified factors, such as organization size, the scope of the transformation project, the analytical tools and technologies involved, and the level of expertise required. 
            
            We request you contact us for a detailed quotation on the digital transformation service."
                }
              }]
            }                                                   
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Our Digital Transformation  <span class='h1-span'>Company’s Work</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title='See how we’ve made an impact' />
          <Cta data={cta2} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Digital Transformation Agency | Consulting & Solutions" description="Transform your business with our digital transformation agency. We consult, empower, and revolutionize your business with digital transformation solutions." />
)
